/**
=========================================================
* ProInterviewer React - v2.0.0
=========================================================

* Product Page: https://www.johanhefer.co.za
* Copyright 2024 Johan Hefer (johanhefer.co.za)

Coded by www.johanhefer.co.za

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from "react";
import AuthApi from "../../../api/auth";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../auth-context/auth.context";

function SignOut() {
  const history = useHistory();
  const { setUser } = useAuth();
  let { user } = useAuth();

  const handleLogout = async () => {
    if (user && user.token) {
      try {
        setUser(null);
        localStorage.removeItem("user");
        history.push("/authentication/sign-in");
      } catch (error) {
        console.error("Logout failed:", error);
      }

      try {
        await AuthApi.Logout(user);
        setUser(null);
        localStorage.removeItem("user");
        history.push("/authentication/sign-in");
      } catch (error) {
        console.error("Logout failed api:", error);
      }
    } else {
      console.warn("User is already null or missing token.");
      history.push("/authentication/sign-in");
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return null;
}

export default SignOut;
