import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import Footer from "examples/Footer"; // Import Footer component
import styles from "examples/LayoutContainers/DashboardLayout/styles";
import { useSoftUIController } from "context";

function LayoutContainer({ children }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction } = controller;
  const { pathname } = useLocation();
  const classes = styles({ miniSidenav, direction });

  useEffect(() => {
    dispatch({ type: "LAYOUT", value: "dashboard" });
  }, [pathname]);

  return (
    <SuiBox
      customClass={classes.layoutContainer}
      display="flex"
      flexDirection="column"
      minHeight="100vh" // Ensure the layout takes up the full viewport height
    >
      <SuiBox
        component="main"
        flex="1" // Allow the main content to expand and push the footer down
        p={3}
      >
        {children}
      </SuiBox>
      <Footer /> {/* Add the Footer component */}
    </SuiBox>
  );
}

// Typechecking props for the LayoutContainer
LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutContainer;
