/**
=========================================================
* ProInterviewer React - v2.0.0
=========================================================

* Product Page: https://www.johanhefer.co.za
* Copyright 2024 Johan Hefer (johanhefer.co.za)

Coded by www.johanhefer.co.za

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// ProInterviewer React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";

// ProInterviewer React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// ProInterviewer React base styles
// import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";

function Dashboard() {
  // const { size } = typography;
  // const { chart, items } = reportsBarChartData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        {/*<SuiBox mb={3}>*/}
        {/*  <Grid container spacing={3}>*/}
        {/*    <Grid item xs={12} sm={6} xl={3}>*/}
        {/*      <MiniStatisticsCard*/}
        {/*        title={{ text: "today's money" }}*/}
        {/*        count="$53,000"*/}
        {/*        percentage={{ color: "success", text: "+55%" }}*/}
        {/*        icon={{ color: "info", component: "paid" }}*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={12} sm={6} xl={3}>*/}
        {/*      <MiniStatisticsCard*/}
        {/*        title={{ text: "today's users" }}*/}
        {/*        count="2,300"*/}
        {/*        percentage={{ color: "success", text: "+3%" }}*/}
        {/*        icon={{ color: "info", component: "public" }}*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={12} sm={6} xl={3}>*/}
        {/*      <MiniStatisticsCard*/}
        {/*        title={{ text: "new clients" }}*/}
        {/*        count="+3,462"*/}
        {/*        percentage={{ color: "error", text: "-2%" }}*/}
        {/*        icon={{ color: "info", component: "emoji_events" }}*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={12} sm={6} xl={3}>*/}
        {/*      <MiniStatisticsCard*/}
        {/*        title={{ text: "sales" }}*/}
        {/*        count="$103,430"*/}
        {/*        percentage={{ color: "success", text: "+5%" }}*/}
        {/*        icon={{*/}
        {/*          color: "info",*/}
        {/*          component: "shopping_cart",*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</SuiBox>*/}
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid>
      </SuiBox>
    </DashboardLayout>
  );
}

export default Dashboard;
