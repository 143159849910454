/**
=========================================================
* ProInterviewer React - v2.0.0
=========================================================

* Product Page: https://johanhefer.co.za/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Johan Hefer (https://johanhefer.co.za)

Coded by johanhefer.co.za

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The pxToRem() function helps you to convert a px unit into a rem unit, 
 */

function pxToRem(number, baseNumber = 16) {
  return `${number / baseNumber}rem`;
}

export default pxToRem;
