import axios from "axios"; // Use double quotes
import { API_SERVER } from ".././config/constant.js"; // Adjust the path as necessary

class ProfileApi {
  static Update = (data) => {
    const { userId, token, ...requestData } = data;

    if (typeof userId === "undefined" || userId === null) {
      console.error("userId is missing from the data object"); // Use double quotes
      console.log("Full data object:", data); // Use double quotes
      return Promise.reject(new Error("userId is missing")); // Use double quotes
    }

    if (!token) {
      console.error("token is missing from the data object"); // Use double quotes
      console.log("Full data object:", data); // Use double quotes
      return Promise.reject(new Error("token is missing")); // Use double quotes
    }

    return axios.put(`${API_SERVER}/api/users/v1/${userId}`, requestData, {
      headers: { Authorization: `Bearer ${token}` }, // Ensure correct formatting
    });
  };
}

export default ProfileApi;
