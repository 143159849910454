import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import ProfileApi from "../../api/profile";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function Overview() {
  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    location: "",
    profession: "", // Add profession to the state
  });

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // Fetch profile data from localStorage
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setProfile(storedUser);
    }
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const response = await ProfileApi.Update({
        userId: profile.userId, // include userId
        email: profile.email,
        mobile: profile.mobile,
        first_name: profile.first_name,
        last_name: profile.last_name,
        location: profile.location,
        profession: profile.profession, // include profession
        token: profile.token,
        // other fields...
      });
      console.log("Profile updated successfully", response);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  return (
    <DashboardLayout>
      <Header />
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card elevation={0}>
              <Box p={2}>
                <Typography variant="h6" mb={2}>
                  {isEditing ? "Edit Profile Information" : "Profile Information"}
                </Typography>

                {[
                  { label: "First Name", name: "first_name", placeholder: "Enter your first name" },
                  { label: "Last Name", name: "last_name", placeholder: "Enter your last name" },
                  { label: "Mobile", name: "mobile", placeholder: "Enter your mobile number" },
                  { label: "Email", name: "email", placeholder: "Enter your email" },
                  { label: "Location", name: "location", placeholder: "Enter your location" },
                  {
                    label: "LinkedIn Profile",
                    name: "linkedinProfile",
                    placeholder: "Enter your LinkedIn profile URL",
                  },
                ].map(({ label, name, placeholder }) => (
                  <TextField
                    key={name}
                    variant="standard"
                    label={label}
                    name={name}
                    value={profile[name]}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    placeholder={placeholder}
                    InputLabelProps={{
                      shrink: true,
                      sx: { color: isEditing ? "inherit" : "rgba(0, 0, 0, 0.54)" },
                    }}
                    InputProps={{
                      readOnly: !isEditing,
                      disableUnderline: true,
                      style: { width: 600 }, // Set width here
                      sx: {
                        padding: "10px 0",
                        "& input": {
                          color: isEditing ? "inherit" : "rgba(0, 0, 0, 0.54)",
                          border: "none",
                        },
                        "& input::placeholder": {
                          color: "rgba(0, 0, 0, 0.38)",
                          opacity: 1,
                        },
                      },
                    }}
                  />
                ))}

                {/* Add the dropdown for profession */}
                <FormControl
                  fullWidth
                  variant="standard"
                  margin="normal"
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: isEditing ? "inherit" : "rgba(0, 0, 0, 0.54)",
                    },
                    "& .MuiSelect-root": {
                      padding: "10px 0",
                      color: isEditing ? "inherit" : "rgba(0, 0, 0, 0.54)",
                    },
                    "& .MuiSelect-select": {
                      paddingLeft: 0,
                    },
                    "& .MuiSelect-icon": {
                      color: "rgba(0, 0, 0, 0.54)",
                    },
                    "& .MuiFormControl-root::before": {
                      borderBottom: !isEditing ? "none" : undefined,
                    },
                    "& .MuiFormControl-root::after": {
                      borderBottom: isEditing ? "1px solid black" : undefined,
                    },
                    "& .MuiFormControl-root:hover:not(.Mui-disabled):before": {
                      borderBottom: isEditing ? "1px solid black" : undefined,
                    },
                  }}
                >
                  <InputLabel shrink>Profession</InputLabel>
                  <Select
                    label="Profession"
                    name="profession"
                    value={profile.profession}
                    onChange={handleChange}
                    displayEmpty
                    IconComponent={ArrowDropDownIcon}
                    inputProps={{
                      readOnly: !isEditing,
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select your profession
                    </MenuItem>
                    <MenuItem value="Software Engineer">Software Engineer</MenuItem>
                    <MenuItem value="Web Developer">Web Developer</MenuItem>
                    <MenuItem value="Data Scientist">Data Scientist</MenuItem>
                    <MenuItem value="UX/UI Designer">UX/UI Designer</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={isEditing ? handleSave : handleEdit}
                  >
                    {isEditing ? "Save" : "Edit"}
                  </Button>
                </Box>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <PlatformSettings />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3} p={3}>
        <Card elevation={0}>
          <Box p={2}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Projects
            </Typography>
            <Typography variant="button" fontWeight="regular" color="text.primary">
              Architects design houses
            </Typography>
          </Box>
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor1}
                  label="project #2"
                  title="modern"
                  description="As Uber works through a huge amount of internal management turmoil."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team1, name: "Elena Morison" },
                    { image: team2, name: "Ryan Milly" },
                    { image: team3, name: "Nick Daniel" },
                    { image: team4, name: "Peterson" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor2}
                  label="project #1"
                  title="scandinavian"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team3, name: "Nick Daniel" },
                    { image: team4, name: "Peterson" },
                    { image: team1, name: "Elena Morison" },
                    { image: team2, name: "Ryan Milly" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor3}
                  label="project #3"
                  title="minimalist"
                  description="Different people have different taste, and various types of music."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team4, name: "Peterson" },
                    { image: team3, name: "Nick Daniel" },
                    { image: team2, name: "Ryan Milly" },
                    { image: team1, name: "Elena Morison" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <PlaceholderCard title={{ variant: "h5", text: "New project" }} outlined />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </DashboardLayout>
  );
}

export default Overview;
