/**
=========================================================
* ProInterviewer React - v2.0.0
=========================================================

* Product Page: https://www.johanhefer.co.za
* Copyright 2024 Johan Hefer (johanhefer.co.za)

Coded by www.johanhefer.co.za

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette }) => ({
  breadcrumbs: {
    "& .MuiBreadcrumbs-separator": {
      color: ({ light }) => (light ? palette.white.main : palette.grey[600]),
    },
  },
}));
