/**
 =========================================================
 * ProInterviewer React - v2.0.0
 =========================================================

 * Product Page: https://johanhefer.co.za/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Johan Hefer (https://johanhefer.co.za)

 Coded by johanhefer.co.za

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import AppBar from "@mui/material/AppBar";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";

// ProInterviewer PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import SuiAvatar from "components/SuiAvatar";

// ProInterviewer PRO React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// ProInterviewer PRO React icons
// import Cube from "examples/Icons/Cube";
// import Document from "examples/Icons/Document";
// import Settings from "examples/Icons/Settings";

// ProInterviewer PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Custom styles for Header
import styles from "layouts/profile/components/Header/styles";

// Images
// import burceMars from "assets/images/bruce-mars.jpg";

function Header() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const classes = styles();
  const [profile, setProfile] = useState({
    fullName: "",
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          const data = JSON.parse(storedUser);
          setProfile({
            fullName: data.first_name + " " + data.last_name,
            title: data.title,
          });
        }
      } catch (error) {
        console.error("Failed to fetch profile information", error);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  return (
    <SuiBox position="relative">
      <DashboardNavbar absolute light />
      <SuiBox customClass={classes.profileHeader_background} />
      <Card className={classes.profileHeader_profile}>
        <Grid container spacing={3} alignItems="center">
          {/*<Grid item>*/}
          {/*  <SuiAvatar*/}
          {/*    src={burceMars}*/}
          {/*    alt="profile-image"*/}
          {/*    variant="rounded"*/}
          {/*    size="xl"*/}
          {/*    customClass="shadow-sm"*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item>
            <SuiBox height="100%" mt={0.5} lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="medium">
                {profile.fullName}
              </SuiTypography>
              <SuiTypography variant="button" textColor="text" fontWeight="medium">
                {profile.title}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </Card>
    </SuiBox>
  );
}

export default Header;
