/**
=========================================================
* ProInterviewer React - v2.0.0
=========================================================

* Product Page: https://johanhefer.co.za/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Johan Hefer (https://johanhefer.co.za)

Coded by johanhefer.co.za

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  defaultProps: {
    disableRipple: true,
  },
};
