import axios from "axios";
import { API_SERVER } from ".././config/constant.js"; // Adjust the path as necessary

class AuthApi {
  static Login = (data) => {
    return axios.post(`${API_SERVER}/api/login/email`, data);
  };

  static Register = (data) => {
    return axios.post(`${API_SERVER}/api/tenants/register`, data);
  };

  static Logout = (data) => {
    return axios.post(`${API_SERVER}/api/logout`, data, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
  };
}

export default AuthApi;
