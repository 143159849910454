import React, { useState } from "react";
import { Box, Card, Typography, TextField, Button, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const questions = [
  "Tell me about yourself.",
  "What are your strengths?",
  "What are your weaknesses?",
  // Add more questions as needed
];

const hints = [
  "Provide a brief overview of your background, experience, and career goals.",
  "Discuss skills that are relevant to the job you're applying for.",
  "Choose weaknesses that you can demonstrate you are working on improving.",
  // Add more hints as needed
];

function Training() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(new Array(questions.length).fill(""));
  const [showHint, setShowHint] = useState(false);

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setShowHint(false); // Hide hint when moving to the next question
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setShowHint(false); // Hide hint when moving to the previous question
  };

  const handleChangeAnswer = (event) => {
    const { value } = event.target;
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestionIndex] = value;
    setAnswers(updatedAnswers);
  };

  const toggleHint = () => {
    setShowHint(!showHint);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box p={2}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Card
              variant="outlined"
              sx={{
                p: 3,
                background: "white",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "300px",
                width: "100%", // Ensure card uses full width
                maxWidth: "600px", // Set a maximum width for larger screens
                margin: "0 auto", // Center the card horizontally
              }}
            >
              <Box>
                <Typography variant="h5" mb={2} sx={{ fontWeight: 600 }}>
                  Interview Trainer
                </Typography>
                <Typography variant="body1" mb={2}>
                  Question {currentQuestionIndex + 1}
                </Typography>
                <Typography variant="body1" mb={2}>
                  {questions[currentQuestionIndex]}
                </Typography>
                <TextField
                  id="answer"
                  label="Your Answer"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  value={answers[currentQuestionIndex]}
                  onChange={handleChangeAnswer}
                  sx={{ mb: 3 }}
                />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleHint}
                  sx={{ mb: 1 }}
                  size="small" // Make buttons smaller for mobile
                >
                  {showHint ? "Hide Hint" : "Show Hint"}
                </Button>
                <Box display="flex" flexDirection="column" gap={1} sx={{ width: "100%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePreviousQuestion}
                    disabled={currentQuestionIndex === 0}
                    sx={{ width: "100%" }}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextQuestion}
                    disabled={currentQuestionIndex === questions.length - 1}
                    sx={{ width: "100%" }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Card>
            {showHint && (
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  background: "#f9f9f9",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="body1" sx={{ mb: 1, fontWeight: 600 }}>
                  Hint
                </Typography>
                <Typography variant="body2">{hints[currentQuestionIndex]}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}

export default Training;
